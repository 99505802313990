import React from 'react'
import { Button, Progress } from 'nq-component'
import FormFactory from '../../components/FormFactory'
import VerifiedRegistrationPresenter from './VerifiedRegistrationPresenter'
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from '../../usecases/object'
import withRouter from '../../withRouter'
import BaseFormPage from '../../base/BaseFormPage'
import Nationalities from '../../assets/json/nationalities.json'
import { Link } from 'react-router-dom'
import verifiedSchema from './verifiedSchema.json'
import verifiedSchemaPage2 from './verifiedSchemaPage2.json'
import verifiedSchemaPage3 from './verifiedSchemaPage3.json'

class VerifiedRegistrationPage extends BaseFormPage {
  constructor(props) {
    super(props)
    this.state = {
      object: {},
      applicationStatus: null,
      advanced: false,
      progress: false,
      user: null,
      step: 0,
      excludeFields: 'nationality',
    }
    this.presenter = new VerifiedRegistrationPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      findObjectUseCase()
    )
  }

  componentDidUpdate(prevState, prevProps) {
    this.presenter.componentDidUpdate(prevState, prevProps)
  }

  onChange(fieldVal, value) {
    this.presenter.onChange(fieldVal, value)
    if (value === 'isFilipino' && fieldVal === 'Yes') {
      this.setState({ excludeFields: 'nationality' })
    } else if (value === 'isFilipino' && fieldVal === 'No') {
      this.setState({ excludeFields: '' })
    } else if (this.state.object.isFilipino === 'No') {
      this.setState({ excludeFields: '' })
    }
  }

  setApplicationStatus(applicationStatus) {
    this.setState({ applicationStatus })
  }
  setStep(step) {
    this.setState({ step })
  }
  onClickStep(val, e) {
    e.preventDefault()
    console.log(val, 'val')
    this.setStep(val)
  }

  getApplicationStatus() {
    return this.state.applicationStatus
  }

  getNationalities() {
    return Nationalities.nationalities.map((item) => item.label)
  }

  getUserDefaultDetails(user) {
    const schema = {
      ...verifiedSchema,
      fields: {
        ...verifiedSchema.fields,
        first_name: {
          ...verifiedSchema.fields.first_name,
          defaultValue: user?.first_name,
        },
        last_name: {
          ...verifiedSchema.fields.last_name,
          defaultValue: user?.last_name,
        },
        nationality: {
          type: 'String',
          _type: 'Enum',
          col: 'col-md-12',
          options: this.getNationalities(),
        },
      },
    }

    return schema
  }

  generateToken() {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''

    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters.charAt(randomIndex)
    }

    const min = 10000
    const max = 99999
    const token = Math.floor(Math.random() * (max - min + 1)) + min

    result += token.toString()

    return result
  }

  getAcl() {
    const roles = this.getCurrentRoles()
    const aclRoles = roles.map((r) => `role:${r.name}`)
    const user = this.getCurrentUser()
    return {
      read: ['*', user.id, ...aclRoles],
      write: ['role:admin', user.id, ...aclRoles],
    }
  }
  navigateBack() {
    this.navigateTo(`/registration/verify?submit=${this.generateToken()}`)
  }

  renderForm() {
    const { applicationStatus, progress, step, excludeFields, object } =
      this.state
    const user = this.getCurrentUser()
    const schema = this.getUserDefaultDetails(user)
    console.log(object)
    if (step === 0) {
      return (
        <form className='row g-3' onSubmit={this.onClickStep.bind(this, 1)}>
          <FormFactory
            // hasLabel={false}
            excludeFields={[excludeFields]}
            schema={schema}
            onChange={this.onChange.bind(this)}
          />
          <div className='d-flex justify-content-end '>
            <button type='submit' className='btn btn-primary'>
              Next
            </button>
          </div>
        </form>
      )
    }

    if (step === 1)
      return (
        <form
          className='row g-3 pt-2'
          onSubmit={this.onClickStep.bind(this, 2)}
        >
          <FormFactory
            schema={verifiedSchemaPage2}
            onChange={this.onChange.bind(this)}
          />
          <div className='d-flex justify-content-between mt-3'>
            <button
              onClick={this.onClickStep.bind(this, 0)}
              className='btn btn-dark text-white'
            >
              Back
            </button>
            <button type='submit' className='btn btn-primary'>
              Next
            </button>
          </div>
        </form>
      )

    if (step === 2)
      return (
        <form className='row g-3' onSubmit={this.onSubmitForm.bind(this)}>
          <FormFactory
            schema={verifiedSchemaPage3}
            onChange={this.onChange.bind(this)}
          />
          <div className='col-md-12'>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                id='signup_cb_terms'
                required
              />
              <label
                className='form-check-label fs-xs'
                htmlFor='signup_cb_terms'
              >
                By submitting this form, you agree to our
                <br />
                <Link to='/terms' target='_blank'>
                  Terms and Conditions.
                </Link>
              </label>
            </div>
          </div>
          <div className='d-flex justify-content-between '>
            <button
              onClick={this.onClickStep.bind(this, 1)}
              className='btn btn-dark text-white '
            >
              Back
            </button>
            <Button
              progress={this.state.progress}
              type='submit'
              className='btn btn-primary'
            >
              {this.state.progress ? 'Please wait...' : 'APPLY'}
            </Button>
          </div>
        </form>
      )
  }

  render() {
    const user = this.getCurrentUser()
    if (!user)
      return (
        <div className='min-vh-100'>
          <Progress />
        </div>
      )
    const { applicationStatus, progress, step } = this.state

    if (progress)
      return (
        <div className='min-vh-100'>
          <Progress />
        </div>
      )

    if (applicationStatus)
      return (
        <div className='min-vh-100 d-flex align-items-center pt-5  flex-column'>
          <h1>Notification</h1>
          <p>Your Request is {applicationStatus.status}</p>
        </div>
      )

    return (
      <div className='min-vh-100 p-4'>
        <div className='d-flex h-100'>
          <div className='col-lg-6 m-auto cotainer'>
            <div className='bg-white shadow rounded p-4 p-lg-5'>
              <h2 className='fw-bold mb-3'>Apply for Verified Account</h2>
              <div className='container-fluid mb-3'>
                <div className='row'>
                  <div className='col-12 d-flex align-items-center'>
                    <div className='d-flex flex-column justify-content-center align-items-center px-2'>
                      <div className='text-center fs-ssm px-3 bg-secondary text-white rounded-circle'>
                        1
                      </div>
                      <div className='fs-ssm m-0 text-secondary '>About</div>
                    </div>
                    <div className='flex-grow-1'>
                      <hr
                        className={`border ${
                          step === 1 || step === 2
                            ? 'border-primary'
                            : 'border-dark'
                        } border-2 opacity-50`}
                      />
                    </div>
                    <div className='d-flex flex-column px-2'>
                      <div
                        className={`${
                          step === 1 || step === 2 ? 'bg-secondary' : 'bg-dark'
                        } text-white px-2 rounded-circle fs-ssm text-center`}
                      >
                        2
                      </div>
                      <div
                        className={`fs-ssm m-0 ${
                          step === 1 || step === 2
                            ? 'text-secondary'
                            : 'text-dark'
                        } `}
                      >
                        Address
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <hr
                        className={`border ${
                          step === 2 ? 'border-primary' : 'border-dark'
                        } border-2 opacity-50`}
                      />
                    </div>
                    <div className='d-flex flex-column px-2'>
                      <div
                        className={`${
                          step === 2 ? 'bg-secondary' : 'bg-dark'
                        } text-white px-3 rounded-circle fs-ssm text-center`}
                      >
                        3
                      </div>
                      <div
                        className={`fs-ssm m-0 ${
                          step === 2 ? 'text-secondary' : 'text-dark'
                        } `}
                      >
                        Upload
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(VerifiedRegistrationPage)

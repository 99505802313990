class CheckoutPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view
    this.findObjectUseCase = findObjectUseCase
  }

  componentDidMount() {
    this.getObjects()
  }

  async getObjects() {
    const collection = 'product'
    const id = this.view.getProductId()

    const query = {
      where: { id },
      include: ['all'],
    }
    this.view.showProgress()
    try {
      const [objects] = await this.findObjectUseCase.execute(collection, query)
      this.view.setProduct(objects)
      this.view.hideProgress()
    } catch (error) {
      this.view.hideProgress()
      this.view.showError(error)
    }
  }
  async makeCheckout() {
    this.view.setProgressCheckout(true)
    try {
      setTimeout(() => {
        this.view.navigateTo('/success')
        this.view.setProgressCheckout(false)
      }, 2000)
    } catch (error) {}
  }
}
export default CheckoutPresenter

import BaseFormPresenter from '../../base/BaseFormPresenter'
import { findObjectUseCase } from '../../usecases/object'

class MarketingRegistrationPresenter extends BaseFormPresenter {
  componentDidMount() {
    this.init()
    this.checkApplicationStatus()
    this.getObject()
  }

  componentDidUpdate(prevState) {
    const prevStatus = prevState.query.submit
    const newStatus = this.view.getQuery().submit
    const user = this.user
    if (prevStatus !== newStatus) {
      this.init()
      this.checkApplicationStatus()
      this.getObject()
    }
  }

  async checkApplicationStatus() {
    try {
      window.scrollTo(0, 0)
      const collection = 'userApplications'
      const id = this.view.getCurrentUser()?.id

      const user = { id }
      const query = {
        limit: 10,
        where: { user: { id: user?.id } },
        // include: ['all'],
        sort: { createdAt: -1 },
      }
      this.view.showProgress()
      const [application] = await this.findObjectUseCase.execute(
        collection,
        query
      )
      this.view.hideProgress()

      // console.log(application, 'test response')

      if (application) {
        this.view.setApplicationStatus(application)
      }
    } catch (error) {
      this.view.setApplicationStatus(null)
      this.view.hideProgress()
      this.view.showError(error)
    }
  }

  async save() {
    const collection = 'userApplications'
    if (this.object.id) {
      this.change.id = this.object.id
    } else {
      this.change.acl = this.view.getAcl()
    }
    try {
      const user = this.view.getCurrentUser()
      this.change.user = { id: user.id }
      this.change.status = 'Pending'
      this.change.typeOfApplication = 'Marketing Partner'
      await this.upsertUseCase.execute(collection, this.change)
    } catch (error) {
      throw error // rethrow the error to be caught by the caller
    }
  }
}

export default MarketingRegistrationPresenter

function formatDollar(number, currency, minimumFractionDigits = 0) {
  return parseFloat(number)
    ?.toLocaleString("en-US", {
      style: "currency",
      currency: currency || "USD",
      minimumFractionDigits,
    })
    .replace("USD", "")
    .trimStart();
}

export default formatDollar;

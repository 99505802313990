import React from "react";
import ProductViewPresenter from "./ProductViewPresenter";
import {
     getObjectUseCase
} from "../../usecases/object";
import {dialog} from "nq-component";
import withRouter from "../../withRouter";

import formatCurrency from "../../formatCurrency";
import ProductCarousel from "./components/ProductCarousel";
import ProductDetailsSkeleton from "./components/ProductDetailsSkeleton";
import formatDollar from "../../formatDollar";
import getCreateYear from "../../getCreateYear";
import BaseFormPage from "../../base/BaseFormPage";

class ProductViewPage extends BaseFormPage {
    constructor(props) {
        super(props);
        this.presenter = new ProductViewPresenter(
            this,
            getObjectUseCase()
        );
    }

    componentDidMount() {
        this.presenter.componentDidMount();
    }

    getCollectionName() {
        return "properties";
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    setProduct(product) {
        this.setState({product});
    }

    setProgresss(progresss) {
        this.setState({progresss});
    }

    closeDialog() {
        dialog.close();
    }

    onClickAddToFavourite() {
        this.presenter.addToFavourites();
    }

    onClickCheckOut(id) {
        this.setState({advanced: true});
    }

    onClickRemoveToFavourite(id) {
        this.presenter.remoteToFavourites(id);
    }

    onClickCheckToFavourite(id) {
        // const carts = this.getCurrentCarts();
        // const [isCartPresent] = carts.filter((item) => item?.product?.id === id);
        // if (isCartPresent) {
        //   this.presenter.remoteToFavourites(isCartPresent?.id);
        // } else {
        //   this.presenter.addToFavourites();
        // }
    }

    render() {
        const product = this.state.object;
        const progresss = this.state.loading;
        const id = this.getParams().id;

        if (progresss) return <ProductDetailsSkeleton/>;
        const seller = product.createdBy;

        return (
            <div className="pb-4 pt-lg-4 pt-0  container min-vh-100">
                <div className="row h-100">
                    <div className="col-lg-6 px-lg-2 px-0 d-flex flex-column align-self-stretch">
                        <ProductCarousel item={product} progresss={progresss}/>
                    </div>
                    <div
                        className="col-lg-6 mt-lg-0 mt-2  border border-2 border-dark border-opacity-25 p-2 px-4 d-flex flex-column align-self-stretch">
                        <div className="row mb-2">
                            <div className="col">
                                <h4 className="text-capitalize fw-semibold ">
                                    {product?.title}
                                </h4>

                            </div>
                        </div>
                        <hr className="border border-dark border opacity-20 mt-0"></hr>
                        <div className="">
                            <h6 className="fw-semibold text-dark">Ask about the property:</h6>
                            <div className="shadow-sm bg-white d-flex justify-content-center p-4 rounded-3">
                                <div className="d-flex align-items-center gap-3">
                                    <div>
                                        <img
                                            src={product.createdBy?.picture || '/placeholder-male.jpeg'}
                                            className="object-fit-cover rounded-circle bg-dark"
                                            style={{height: 75, width: 75}}
                                        />
                                    </div>

                                    <div className="text-start">
                                        <h5 className="mb-1">{`${product?.createdBy?.first_name} ${product?.createdBy?.last_name}`}</h5>
                                        {product?.createdBy?.isBroker ? (
                                            <span className="m-0 badge rounded-pill bg-primary">
                        <i className="bi bi-shield-fill-check"></i> PROFESSIONAL
                        BROKER
                      </span>
                                        ) : null}
                                        {product?.createdBy?.isVerified ? (
                                            <span className="m-0 badge rounded-pill bg-primary">
                        <i className="bi bi-shield-fill-check"></i> FULLY
                        VERIFIED
                      </span>
                                        ) : null}
                                        <p className="text-dark fw-lighter mb-0">
                                            MEMBER SINCE{" "}
                                            {getCreateYear(seller?.createdAt)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="border border-dark border opacity-20"></hr>
                        {product.showPrice === true ? (
                            <>
                                <h2 className="fw-semibold text-secondary fs-4 mb-1 ">
                                    {formatCurrency(product?.pricePeso)}
                                </h2>
                                <div className="mb-3 ">
                  <span className="text-dark">
                    {product?.priceDollar && formatDollar(product?.priceDollar)}
                  </span>
                                </div>
                            </>
                        ) : (
                            <h6 className="fw-semibold text-secondary py-1">
                                <i className="bi bi-exclamation-triangle"></i> Prices are
                                currently hidden by our agent. Click the button for more
                                details.
                            </h6>
                        )}
                        {
                            this.state.advanced && (
                                <div className="mb-3">
                                    <h6 className="fw-semibold text-dark">Number</h6>
                                    <p className="text-dark">{seller?.mobile}</p>
                                </div>
                            )
                        }
                        {
                            !this.state.advanced && (
                                <button
                                    className="btn btn-primary text-white fw-semibold "
                                    onClick={this.onClickCheckOut.bind(this, id)}>
                                    Show Number <i className="bi bi-telephone-fill"></i>
                                </button>
                            )
                        }

                    </div>
                    <div className="container-fluid bg-white  mt-3 col-12  p-4">
                        <h4 className="fw-semibold  ">Product Description</h4>
                        <hr className="border border-dark border opacity-20"></hr>
                        <div className="row">
                            <p className="col-md-8">{product?.description}</p>
                        </div>
                    </div>

                    <div className="container-fluid bg-white  mt-3 col-12 rounded-1  p-4">
                        <h4 className="fw-semibold"> About The Property</h4>
                        <hr className="border border-dark border opacity-20"></hr>

                        <table className="table table-striped">
                            <tbody>
                            <tr>
                                <td className="text-dark">Offer</td>
                                <td className="text-primary fw-semibold text-center">
                                    {product?.offer}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-dark">Type</td>
                                <td className="text-primary fw-semibold text-center">
                                    {product?.category}
                                </td>
                            </tr>
                            {product.category !== "Commercial" &&
                                product.category !== "Land" && (
                                    <>
                                        <tr>
                                            <td className="text-dark">Bedroom</td>
                                            <td className="text-primary fw-semibold text-center">
                                                {product?.bedrooms}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-dark">Bathroom</td>
                                            <td className="text-primary fw-semibold text-center">
                                                {product?.bathrooms}
                                            </td>
                                        </tr>
                                    </>
                                )}
                            {product.category !== "Land" && (
                                <tr>
                                    <td className="text-dark">Floor Area</td>
                                    <td className="text-primary fw-semibold text-center">
                                        {product?.floorArea} &#x33A1;
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td className="text-dark">Land Size</td>
                                <td className="text-primary fw-semibold text-center">
                                    {product?.landSize} &#x33A1;
                                </td>
                            </tr>
                            <tr>
                                <td className="text-dark">Unit Number</td>
                                <td className="text-primary fw-semibold text-center">
                                    {product?.unit} &#x33A1;
                                </td>
                            </tr>
                            <tr>
                                <td className="text-dark">
                                    Enter{" "}
                                    {product.category === "Condominium"
                                        ? "Condominium"
                                        : product.category === "Commercial"
                                            ? "Building"
                                            : "Subdivision "}{" "}
                                    Name
                                </td>
                                <td className="text-primary fw-semibold text-center">
                                    {product?.name}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container-fluid bg-white p-4  mt-3 ">
                        <h4 className="fw-semibold  ">Indoor features</h4>
                        <hr className="border border-dark border opacity-20"></hr>
                        <div className="d-flex flex-wrap gap-1 mb-5">
                            {product?.indoor?.map((item, i) => (
                                <span
                                    key={i}
                                    className="text-nowrap text-capitalize p-2 bg-secondary text-white rounded-2 "
                                >
                  {item}
                </span>
                            ))}
                        </div>
                        <h4 className="fw-semibold  ">Outdoor features</h4>
                        <hr className="border border-dark border opacity-20"></hr>
                        <div className="d-flex flex-wrap gap-1">
                            {product?.outdoor?.map((item, i) => (
                                <span
                                    key={i}
                                    className="text-nowrap text-capitalize p-2 bg-secondary text-white rounded-2 "
                                >
                  {item}
                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ProductViewPage);

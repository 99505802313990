import React, { Component } from 'react'
import withRouter from '../../withRouter'
import BaseListPage from '../../base/BaseListPage'
import ResultListPresenter from './ResultListPresenter'
import { findObjectUseCase } from '../../usecases/object'
import { InfiniteScroll } from 'nq-component'
import ProductListItem from '../../components/card/ProductListItem'

class ResultListPage extends BaseListPage {
  constructor(props) {
    super(props)
    this.presenter = new ResultListPresenter(this, findObjectUseCase())
    this.state = {
      objects: [],
      count: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.presenter.componentDidUpdate(prevProps, prevState)
  }
  scrollToTop() {
    window.scrollTo(0, 0)
  }

  getSearchInput() {
    const params = this.getQuery()
    return params.product
  }

  onClickProduct(id) {
    this.navigateTo(`/products/details/${id}`)
  }

  render() {
    const { objects, count, progress } = this.state

    const params = this.getQuery().product
    return (
      <div className='container min-vh-100 pt-5'>
        <div>
          <p className='mb-0'>Search: {params}</p>
          <p className=''>
            {count} {count > 1 ? 'Properties' : 'Property'} Found
          </p>
        </div>
        <div
          className='container overflow-y-auto'
          style={{ maxHeight: '42.5rem' }}
        >
          {/* <h1 className='mb-2'>Product List</h1> */}
          <InfiniteScroll
            // className='h-100'
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects?.length}
          >
            <div className='row'>
              {objects?.map((item, i) => (
                <ProductListItem
                  key={i}
                  product={item}
                  onClick={this.onClickProduct.bind(this, item?.id)}
                />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    )
  }
}
export default withRouter(ResultListPage)

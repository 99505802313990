import createPromise from 'nq/src/createPromise'
import BaseFormPresenter from '../../base/BaseFormPresenter'
import { findObjectUseCase } from '../../usecases/object'
import ConfirmDialog from '../../base/ProgressDialog'
import { dialog } from 'nq-component'

class BrokerRegistrationPresenter extends BaseFormPresenter {
  componentDidMount() {
    this.init()
    this.checkApplicationStatus()
    this.getObject()
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevState) {
    const prevStatus = prevState.query.submit
    const newStatus = this.view.getQuery().submit
    if (prevStatus !== newStatus) {
      this.init()
      this.checkApplicationStatus()
      this.getObject()
    }
  }

  // show success dialog
  showSuccess(message, title) {
    const options = {
      title: title || 'Success',
      message: message,
      icon: 'bi bi-check-circle',
      type: 'success',
      positiveButton: 'OKAY',
      negativeButton: false,
    }
    return this.showDialog(options)
  }

  // the generic popup dialog
  showDialog({ title, message, icon, type, ...options }) {
    const promise = createPromise()
    dialog.fire({
      html: (
        <ConfirmDialog
          icon={icon}
          title={title}
          message={message}
          type={type}
        />
      ),
      onPositiveClick: () => {
        promise.resolve()
      },
      onNegativeClick: () => {
        promise.reject()
      },
      ...options,
    })
    return promise
  }

  async checkApplicationStatus() {
    try {
      window.scrollTo(0, 0)
      const collection = 'brokerApplications'
      const user = this.view.getCurrentUser()
      const query = {
        where: { user: { id: user?.id } },
        include: ['all'],
        sort: { createdAt: -1 },
      }
      this.view.showProgress()
      const [application] = await findObjectUseCase().execute(collection, query)
      this.view.hideProgress()
      console.log(application)
      if (application) {
        this.view.setApplicationStatus(application)
      }
    } catch (error) {
      this.view.setApplicationStatus(null)
      this.view.hideProgress()
      this.view.showError(error)
    }
  }

  async save() {
    const collection = 'brokerApplications'
    if (this.object.id) {
      this.change.id = this.object.id
    } else {
      this.change.acl = this.view.getAcl()
    }
    try {
      const user = this.view.getCurrentUser()
      this.change.user = { id: user.id }
      this.change.status = 'Pending'
      await this.upsertUseCase.execute(collection, this.change)
      await this.view.showSuccess('Successfully submmited!')
    } catch (error) {
      throw error // rethrow the error to be caught by the caller
    }
  }
}

export default BrokerRegistrationPresenter

import React, { Component } from 'react'
import withRouter from '../../withRouter'
import BasePage from '../../base/BasePage'
import Seller from '../../assets/seller.jpg'
class SuccessPage extends BasePage {
  onClickHome() {
    this.navigateTo('/')
  }

  render() {
    return (
      <div className='container min-vh-100 d-flex justify-content-center align-items-center'>
        <div className='bg-white p-4 text-center'>
          <div className='bg-sign p-4'>
            <p className='mb-0 fw-bold fs-4'>Your message has been sent</p>
            Directly get in touch below.
          </div>
          <div className=' d-flex align-items-center gap-3 mt-3 p-4 rounded-3'>
            <div>
              <img
                src={Seller}
                className='object-fit-cover rounded-circle bg-dark'
                style={{ height: 75, width: 75 }}
              />
            </div>

            <div className='text-start'>
              <h5 className='mb-1'>Bentong RealEstate Company</h5>
              <p className=' fw-bold text-success mb-0'>
                <i class='bi bi-shield-fill-check'></i> FULLY VERIFIED
              </p>
              <p className='text-dark fw-lighter mb-0'>MEMBER SINCE 2021</p>
            </div>
          </div>
          <div>
            <h3 className='fw-semibold'>+639992210530</h3>
          </div>

          <button
            onClick={this.onClickHome.bind(this)}
            className='btn btn-secondary text-white fw-semibold fs-6 mt-3 w-100'
          >
            Find Related Properties
          </button>
        </div>
      </div>
    )
  }
}
export default withRouter(SuccessPage)

import React, { Component } from 'react'
import withRouter from '../../withRouter'
import AccountPage from '../account/AccountPage'
import BasePage from '../../base/BasePage'
import SubscriptionPage from '../subscription/SubscriptionPage'

class ProfilePage extends BasePage {
  constructor(props) {
    super(props)
    this.state = {
      active: 1,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onClickAccount() {
    this.setState({ active: 1 })
  }

  onClickSubs() {
    this.setState({ active: 2 })
  }

  render() {
    const { active } = this.state
    const roles = this.getCurrentRoles()

    return (
      <div className='container min-vh-100 pt-5'>
        <div className='d-flex'>
          <a
            className={`${
              active === 1 ? 'border-bottom border-primary' : ''
            } py-2 px-3`}
            role='button'
            onClick={this.onClickAccount.bind(this)}
          >
            Account
          </a>
          {roles?.some((item) => item.name === 'seller') && (
            <a
              className={`${
                active === 2 ? 'border-bottom border-primary' : ''
              } py-2 px-3`}
              role='button'
              onClick={this.onClickSubs.bind(this)}
            >
              Subscription
            </a>
          )}
        </div>
        <div>
          {active === 1 ? (
            <AccountPage />
          ) : active === 2 ? (
            <SubscriptionPage />
          ) : null}
        </div>
      </div>
    )
  }
}
export default withRouter(ProfilePage)

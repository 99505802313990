import React, { Component } from 'react'

class FavouritePresenter {
  constructor(view, findObjectUseCase, deleteObjectUseCase) {
    this.view = view
    this.findObjectUseCase = findObjectUseCase
    this.deleteObjectUseCase = deleteObjectUseCase
  }

  componentDidMount() {
    // this.init()
    this.getObjects()
  }

  async getObjects() {
    window.scrollTo(0, 0)
    const collection = 'cart'
    const user = this.view.getCurrentUser()

    const query = {
      where: { user: { id: user?.id } },
      include: ['product', 'user'],
      sort: { createdAt: -1 },
    }
    this.view.setGetProgress(true)
    try {
      const objects = await this.findObjectUseCase.execute(collection, query)
      this.view.setCarts(objects)
      this.view.setGetProgress(false)
    } catch (error) {
      this.view.hideProgress()
      this.view.setGetProgress(false)
    }
  }
  async removeFavourites(id) {
    try {
      // console.log(id)
      await this.view.showDialog({
        message: 'Are you sure you want to remove this item?',
      })
      const collection = 'cart'
      this.view.showProgress()
      await this.deleteObjectUseCase.execute(collection, id)
      const cartCount = this.view.getCurrentCarts()
      const carts = this.view.getCarts()
      this.view.setCurrentCarts(cartCount?.filter((item) => item.id !== id))
      this.view.setCarts(carts?.filter((item) => item.id !== id))
    } catch (error) {
      this.view.hideProgress()
      this.view.showError(error)
    }
  }
  async removeFavouritesCheck(id) {
    try {
      const collection = 'cart'
      this.view.showProgress()
      await this.deleteObjectUseCase.execute(collection, id)
      const cartCount = this.view.getCurrentCarts()
      const carts = this.view.getCarts()
      this.view.setCurrentCarts(cartCount?.filter((item) => item.id !== id))
      this.view.setCarts(carts?.filter((item) => item.id !== id))
    } catch (error) {
      this.view.hideProgress()
      this.view.showError(error)
    }
  }
  async checkoutNow(id) {
    try {
      const user = this.view.getCurrentUser()
      if (user) {
        this.view.navigateTo(`/products/checkout/${id}`)
      } else {
        this.view.navigateTo('/signin')
      }
    } catch (error) {}
  }
}
export default FavouritePresenter

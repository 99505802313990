import React, { Component } from 'react'
import BasePage from '../../base/BasePage'
import { findObjectUseCase, upsertUseCase } from '../../usecases/object'
import SubscriptionPresenter from './SubscriptionPresenter'
import BaseListPage from '../../base/BaseListPage'
import formatCurrency from '../../formatCurrency'
import { Progress } from 'nq-component'

export default class SubscriptionPage extends BaseListPage {
  constructor(props) {
    super(props)
    this.presenter = new SubscriptionPresenter(
      this,
      findObjectUseCase(),
      upsertUseCase()
    )
    this.state = {
      subscriptions: [],
      progress: false,
    }
  }
  componentDidMount() {
    this.presenter.componentDidMount()
  }
  setSubcriptions(subscriptions) {
    this.setState({ subscriptions })
  }
  setProgresss(progresss) {
    this.setState({ progresss })
  }
  onClickAvail(listings) {
    this.presenter.availNow(listings)
  }
  render() {
    const { subscriptions, progresss } = this.state
    if (progresss)
      return (
        <div className='min-vh-100'>
          <Progress />
        </div>
      )
    const user = this.getCurrentUser()
    // console.log(user)
    return (
      <div className='container bg-white py-4 mt-2  shadow'>
        <div className='px-2 px-lg-5'>
          <h1 className='fs-3 fw-semibold '>POST YOUR ADS</h1>
          <hr className='border border-primary opacity-50 mt-0 ' />
        </div>
        <div className='px-2 px-lg-5 row'>
          {subscriptions?.map((items) => (
            <div className='col-3 p-1' key={items.id}>
              <div className='d-flex flex-column justify-content-center align-items-center border rounded-2'>
                <div className='p-2 fs-5 border-bottom border-1 fw-lighter bg-lighter rounded-top-2 shadow-sm w-100 text-center'>
                  {items.listings} LISTINGS
                </div>
                <div className='py-2 text-center w-100'>
                  <h2 className='pt-3 text-secondary fw-semibold  '>
                    RealEstate
                  </h2>
                  <h5 className='fw-bold'>{formatCurrency(items.price)}</h5>
                  <button
                    onClick={this.onClickAvail.bind(this, items.listings)}
                    className='btn btn-skeleton mt-2 mb-3 w-75'
                  >
                    <i className='bi bi-cart-plus'></i> Avail Now!
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

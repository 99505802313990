import BaseFormPresenter from "../../base/BaseFormPresenter";

class ProductViewPresenter extends BaseFormPresenter{
  // constructor(view, findObjectUseCase, saveObjectUseCase, deleteObjectUseCase) {
  //   this.view = view
  //   this.findObjectUseCase = findObjectUseCase
  //   this.saveObjectUseCase = saveObjectUseCase
  //   this.deleteObjectUseCase = deleteObjectUseCase
  // }

  // componentDidMount() {
  //   // this.init()
  //   this.getObjects()
  // }
  //
  // async getObjects() {
  //   this.view.scrollToTop()
  //   const collection = 'product'
  //   const id = this.view.getProductId()
  //
  //   const query = {
  //     where: { id },
  //     include: ['all'],
  //   }
  //   this.view.setProgresss(true)
  //   try {
  //     const [objects] = await this.findObjectUseCase.execute(collection, query)
  //     this.view.setProduct(objects)
  //
  //     this.view.setProgresss(false)
  //   } catch (error) {
  //     this.view.setProgresss(false)
  //     this.view.showError(error)
  //   }
  // }
  //
  // async addToFavourites() {
  //   try {
  //     const user = this.view.getCurrentUser()
  //     const id = this.view.getParams().id
  //
  //     if (user) {
  //       const object = {
  //         user: { id: user.id },
  //         product: { id },
  //         acl: {
  //           read: [user?.id, '*'],
  //           write: [user?.id, '*'],
  //         },
  //       }
  //       const data = await this.saveObjectUseCase.execute('cart', object)
  //       const carts = this.view.getCurrentCarts()
  //       this.view.setCurrentCarts([...carts, data])
  //     } else {
  //       this.view.navigateTo('/signin')
  //     }
  //   } catch (error) {
  //     this.view.showError(error)
  //   }
  // }
  //
  // async remoteToFavourites(id) {
  //   try {
  //     const collection = 'cart'
  //     this.view.showProgress()
  //
  //     await this.deleteObjectUseCase.execute(collection, id)
  //     const carts = this.view.getCurrentCarts()
  //     this.view.setCurrentCarts(carts?.filter((item) => item.id !== id))
  //   } catch (error) {
  //     this.view.hideProgress()
  //     this.view.showError(error)
  //   }
  // }
  async checkoutNow(id) {
    // try {
    //   const user = this.view.getCurrentUser()
    //   if (user) {
    //     console.log(id)
    //     this.view.navigateTo(`/products/checkout/${id}`)
    //   } else {
    //     this.view.navigateTo('/signin')
    //   }
    // } catch (error) {}
  }
}

export default ProductViewPresenter

import React from 'react'

function index({ status, role }) {
  return (
    <div className='min-vh-100 p-4'>
      <div className='container text-center'>
        <div className='m-auto'>
          <StatusFactory status={status} role={role} />
        </div>
      </div>
    </div>
  )
}

function StatusFactory({ status, role }) {
  if (status === 'Pending') {
    return (
      <>
        <h1>Notification</h1>
        <p>
          Your Request as a "{role}" is Being Processed. Please Wait for the
          approval of administrator
        </p>
      </>
    )
  }

  if (status === 'Rejected') {
    return (
      <>
        <h1>Notification</h1>
        <p>
          Your request as a "{role}"" has been carefully reviewed by the
          administrator, but we regret to inform you that it has been rejected.
          If you have any questions or need further clarification, please feel
          free to reach out to us. Thank you for your understanding.
        </p>
      </>
    )
  }

  if (status === 'Accepted') {
    return (
      <>
        <h1>Notification</h1>
        <p>
          Your request as a "{role}" has been reviewed and approved by the
          administrator. We are pleased to inform you that it has been accepted.
          If you have any further questions or require additional assistance,
          please do not hesitate to contact us. Thank you for choosing our
          services.
        </p>
      </>
    )
  }
}

export default index

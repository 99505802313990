import React from 'react'
import { Button, Progress } from 'nq-component'
import FormFactory from '../../components/FormFactory'
import SellerRegistrationPresenter from './SellerRegistrationPresenter'
import { getObjectUseCase, upsertUseCase } from '../../usecases/object'
import withRouter from '../../withRouter'
import BaseFormPage from '../../base/BaseFormPage'
import NavBar from '../../components/navbar'
import { Link } from 'react-router-dom'
import sellerSchema from './sellerSchema.json'
import Notice from '../../components/Notice'

class SellerRegistrationPage extends BaseFormPage {
  constructor(props) {
    super(props)
    this.state = {
      object: {},
      applicationStatus: null,
      advanced: false,
      progress: false,
      excludeFields: 'prc_id',
    }
    this.presenter = new SellerRegistrationPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    )
  }

  getCollectionName(){
    return "sellerApplications"
  }

  componentDidUpdate(prevState, prevProps) {
    this.presenter.componentDidUpdate(prevState, prevProps)
  }

  setApplicationStatus(applicationStatus) {
    this.setState({ applicationStatus })
  }

  getUserDefaultDetails(user) {
    const schema = {
      ...sellerSchema,
      fields: {
        ...sellerSchema.fields,
        first_name: {
          ...sellerSchema.fields.first_name,
          defaultValue: user?.first_name,
        },
        last_name: {
          ...sellerSchema.fields.last_name,
          defaultValue: user?.last_name,
        },
      },
    }

    return schema
  }

  onChange(fieldVal, value) {
    console.log(fieldVal, value)
    this.presenter.onChange(fieldVal, value)
    if (value === 'isBroker' && fieldVal === true) {
      this.setState({ excludeFields: '' })
    } else if (value === 'isBroker' && fieldVal === false) {
      this.setState({ excludeFields: 'prc_id' })
    } 
  }

  setApplicationStatus(applicationStatus) {
    this.setState({ applicationStatus })
  }

  getApplicationStatus() {
    return this.state.applicationStatus
  }

  generateToken() {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''

    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters.charAt(randomIndex)
    }

    const min = 10000
    const max = 99999
    const token = Math.floor(Math.random() * (max - min + 1)) + min

    // Combine the random characters and the random number
    result += token.toString()

    return result
  }

  getAcl() {
    const roles = this.getCurrentRoles()
    const aclRoles = roles.map((r) => `role:${r.name}`)
    const user = this.getCurrentUser()
    return {
      read: ['*', user.id, ...aclRoles],
      write: ['role:admin', user.id, ...aclRoles],
    }
  }

  navigateBack() {
    window.scrollTo(0,0)
    this.navigateTo(`/registration/seller?submit=${this.generateToken()}`)
  }

  render() {
    const { applicationStatus, progress, object, excludeFields } = this.state
    console.log(object)
    const user = this.getCurrentUser()
    const schema = this.getUserDefaultDetails(user)
    // const schema = this.getSchema(this.getCollectionName())
    if (progress)
      return (
        <div className='min-vh-100'>
          <Progress />
        </div>
      )
    if (applicationStatus)
      return (
        <Notice
          status={applicationStatus.status}
          role={'Seller'}
        />
      )

    return (
      <div className='min-vh-100 p-4'>
        <div className='d-flex h-100'>
          <div className='col-md-5 m-auto cotainer'>
            <div className='bg-white shadow rounded p-4 p-lg-5'>
              <h2 className='fw-bold mb-3'>Apply as Seller</h2>
              <form onSubmit={this.onSubmitForm.bind(this)}>
                <div className='row g-3'>
                  <FormFactory
                    // hasLabel={false}
                    excludeFields={[excludeFields]}
                    schema={schema}
                    onChange={this.onChange.bind(this)}
                  />
                  <div className='col-md-12'>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='signup_cb_terms'
                        required
                      />
                      <label
                        className='form-check-label fs-xs'
                        htmlFor='signup_cb_terms'
                      >
                        By submitting this form, you agree to our
                        <br />
                        <Link to='/terms' target='_blank'>
                          Terms and Conditions.
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className='text-center'>
                    <Button
                      progress={this.state.progress}
                      type='submit'
                      className='btn btn-primary w-50'
                    >
                      {this.state.progress ? 'Please wait...' : 'APPLY'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SellerRegistrationPage)

function formatTimeAgo(dateString) {
  const currentDate = new Date()
  const inputDate = new Date(dateString)

  const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000)

  if (timeDifferenceInSeconds < 60) {
    return `${timeDifferenceInSeconds} seconds ago`
  } else if (timeDifferenceInSeconds < 3600) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60)
    return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`
  } else if (timeDifferenceInSeconds < 86400) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600)
    return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`
  } else {
    const daysAgo = Math.floor(timeDifferenceInSeconds / 86400)
    return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`
  }
}

export default formatTimeAgo

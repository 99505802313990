import Queue from "nq";
import React, { useState } from "react";

export default function ProductCarousel({ item }) {
  // console.log(item?.photos.map((photo) => photo?.photos))
  return (
    <div className="d-flex flex-column">
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
        style={{ height: 350 }}
      >
        <div className="carousel-inner">
          {[item?.thumbnail, ...(item?.photos || []).map((photo) => photo)].map(
            (img, i) => (
              <div
                key={i}
                className={`carousel-item ${i === 0 ? "active" : ""}`}
              >
                <img
                  // Make sure to add a unique key to each image when using map
                  src={Queue.File.getFile(img || "")}
                  className="d-block w-100  object-fit-cover "
                  alt="..."
                  style={{ height: 360 }}
                />
              </div>
            )
          )}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="d-flex overflow-x-auto gap-1 mt-3">
        {[item?.thumbnail, ...(item?.photos || []).map((photo) => photo)].map(
          (img, i) => (
            <img
              key={i} // Make sure to add a unique key to each image when using map
              src={Queue.File.getFile(img || "")}
              className={`d-block object-fit-cover `}
              alt="..."
              style={{ height: 60, width: 85 }}
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide-to={i}
            />
          )
        )}
      </div>
    </div>
  );
}

import React from "react";
import formatCurrency from "../../../formatCurrency";
import Queue from "nq";
function FavouriteCard({
  carts,
  onClickProduct,
  onClickRemove,
  onClickCheckout,
}) {
  console.log(carts);
  return (
    <>
      {carts.length === 0 ? (
        <h1 className="text-secondary fw-bold ">No Items Found. Add Now!!!</h1>
      ) : (
        carts
          ?.filter((obj) => obj.hasOwnProperty("product"))
          .map((fav) => (
            <div
              key={fav?.id}
              className="border border-2 border-end-0 border-start-0  px-2 py-1 "
              onClick={() => onClickProduct(fav?.product?.id)}
              role="button"
            >
              <div className="d-flex flex-column flex-md-row gap-2  justify-content-between p-2 ">
                <div className="d-flex gap-4 flex-md-row flex-column align-items-center ">
                  <img
                    className="img-fluid object-fit-cover rounded-1 "
                    src={Queue.File.getFile(fav?.product?.thumbnail || "")}
                    style={{ width: "15em", height: "8em" }}
                  />
                  <div>
                    <h2 className="text-capitalize fw-semibold m-0">
                      {fav?.product?.title}
                    </h2>
                    <p className="fw-lighter text-dark mb-1">
                      <i className="bi bi-geo-alt"></i>{" "}
                      {fav?.product?.address ? fav?.product?.address : `Brgy. `}
                    </p>
                    <h4 className="fw-semibold text-primary ">
                      {formatCurrency(fav?.product?.pricePeso)}
                    </h4>
                  </div>
                </div>
                <div className="d-flex flex-md-column gap-2 justify-content-center align-items-center ">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickRemove(fav?.id);
                    }}
                    className="btn btn-danger fw-semibold"
                    role="button"
                  >
                    Remove
                  </button>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickCheckout(fav?.product?.id, fav?.id);
                    }}
                    className="btn btn-primary fw-semibold"
                  >
                    Overview
                  </button>
                </div>
              </div>
            </div>
          ))
      )}
    </>
  );
}
export default FavouriteCard;

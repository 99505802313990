import { InputString } from "nq-component";
import React from "react";
import { Link } from "react-router-dom";
import BasePage from "../../base/BasePage";

import ForgotPresenter from './ForgotPresenter'
import { resetPasswordUseCase } from "../../usecases/user";

class ForgotPage extends BasePage {

  constructor(props){
    super(props)
    this.presenter = new ForgotPresenter(this, resetPasswordUseCase())
    this.state = {user: { email: ''}}
  }

  onResetPassword(e){
    e.preventDefault()
    this.presenter.forgotPassword(this.state.user.email)
  }

  render() {

    const { user } = this.state

    return (
      <div className="vh-100 d-flex flex-column justify-content-center p-0 bgroundContainer1">
      <div className="container mainContent1 p-lg-3 p-0 d-flex justify-content-center align-items-center ">
        <div className="container p-lg-3 p-0 d-flex justify-content-center align-items-center">
          <div className="bg-white shadow rounded">
            <div className="container-fluid p-0" style={{ maxWidth: "420px" }}>
              <div className="d-flex justify-content-center text-center w-100">
                <h1 className="text-primary">EBOWR</h1>
              </div>

              <div className="container p-5 pt-0 pb-4 mt-lg-0 mt-3">
                <h3 className="fw-bold">Forgot Password?</h3>
                <p className="text-muted mb-3">
                  Enter your email and we'll send you a link to reset your
                  password.
                </p>
                <form className="mb-4">
                  <label className="form-label fs-sm">Email Address</label>
                  <InputString
                    className="form-control mb-3"
                    field="email"
                    type="email"
                    autocomplete="nope"
                    placeholder="Email Address"
                    object={user}
                  />
                  <div className="text-center">
                    <button onClick={this.onResetPassword.bind(this)}  className="w-50 btn btn-primary">SEND</button>
                  </div>
                </form>
                <div className="text-center">
                  <Link to="/signin" className="btn btn-link link-dark">
                    <i className="bi bi-arrow-left me-2"></i>Back to sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default ForgotPage;
import React from 'react'

export function Bed(props) {
  return (
    <svg
      viewBox='0 0 512 512'
      fill='currentColor'
      height={props.height || '1.2em'}
      width={props.width || '1.2em'}
    >
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={32}
        d='M384 240H96V136a40.12 40.12 0 0140-40h240a40.12 40.12 0 0140 40v104zM48 416V304a64.19 64.19 0 0164-64h288a64.19 64.19 0 0164 64v112'
      />
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={32}
        d='M48 416v-8a24.07 24.07 0 0124-24h368a24.07 24.07 0 0124 24v8M112 240v-16a32.09 32.09 0 0132-32h80a32.09 32.09 0 0132 32v16M256 240v-16a32.09 32.09 0 0132-32h80a32.09 32.09 0 0132 32v16'
      />
    </svg>
  )
}

export function Toilet(props) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      height={props.height || '7em'}
      width={props.width || '.7em'}
    >
      <path d='M9 22h8v-2.5c2.41-1.63 4-4.38 4-7.5V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v8H3c0 3.09 2 6 6 7.5V22m-3.71-8h13.42A7.017 7.017 0 0115 18.33V20h-4v-1.67C9 18 5.86 15.91 5.29 14M15 4h4v8h-4V4m1 1v3h2V5h-2z' />
    </svg>
  )
}

export function Size(props) {
  return (
    <svg
      viewBox='0 0 512 512'
      fill='currentColor'
      height={props.height || '1.2em'}
      width={props.width || '1.2em'}
    >
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='square'
        strokeMiterlimit={10}
        strokeWidth={32}
        d='M304 96h112v112M405.77 106.2L111.98 400.02M208 416H96V304'
      />
    </svg>
  )
}

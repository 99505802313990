import browseFile from '../../browseFile'
import csvToJson from '../../csvToJson'
import unflatten from '../../unflatten'
import jsonToObject from '../../jsonToObject'
import BaseListPresenter from '../../base/BaseListPresenter'

class SubscriptionPresenter {
  constructor(view, findObjectUseCase, upsertUseCase) {
    this.view = view
    this.findObjectUseCase = findObjectUseCase
    this.upsertUseCase = upsertUseCase
  }

  componentDidMount() {
    // this.init()
    this.getObjects()
  }

  async getObjects() {
    const collection = 'subscription'

    const query = {
      include: ['all'],
    }
    this.view.setProgresss(true)
    try {
      const objects = await this.findObjectUseCase.execute(collection, query)

      this.view.setSubcriptions(objects)

      this.view.setProgresss(false)
    } catch (error) {
      this.view.setProgresss(false)
      this.view.showError(error)
    }
  }
  async availNow(listings) {
    const collection = 'users'

    const boost = this.view.getCurrentUser()
    if (boost.boosting === undefined) {
      boost.boosting = listings
    } else {
      boost.boosting += listings
    }

    // console.log(currentBoost)

    this.view.setProgresss(true)
    try {
      await this.upsertUseCase.execute(collection, boost)

      this.view.setProgresss(false)
      this.view.showSuccess(`Availed ${listings} Listings Successfully!`)
    } catch (error) {
      this.view.setProgresss(false)
      this.view.showError(error)
    }
  }
}

export default SubscriptionPresenter

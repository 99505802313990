import React from 'react'
import MainPagePresenter from './MainPagePresenter'
import {getAllSchemasUseCase} from '../../usecases/schema/usecases'
import {findObjectUseCase} from '../../usecases/object'
import {getCurrentUserUseCase, signOutUseCase} from '../../usecases/user'
import {Routes, Route} from 'react-router-dom'
import BasePage from '../../base/BasePage'
import NotFoundPage from '../notfound'
import {Layout} from 'nq-component'
import withRouter from '../../withRouter'
import ProductDetailsPage from '../product-view/ProductViewPage'
import FavoritePage from '../favourite/FavouritePage'
import CheckoutPage from '../checkout/CheckoutPage'
import SuccessPage from '../success/SuccessPage'
import MainNavBar from './component/MainNavBar'
import HomePage from '../home/HomePage'
import MainFooter from './component/MainFooter'
import ResultListPage from '../SearchResult/ResultListPage'
import SellerRegistrationPage from '../seller-registration/SellerRegistrationPage'
import MarketingRegistrationPage from '../marketing-registration/MarketingRegistrationPage'
import NewProductListPage from '../new-product-list/NewProductListPage'
import ProfilePage from '../profile-page/ProfilePage'
import GetVerifiedPage from '../get-verified/GetVerifiedPage'
import BrokerRegistrationPage from '../broker-registration/BrokerRegistrationPage'
import VerifiedRegistrationPage from '../verified-registration/VerifiedRegistrationPage'
import FeaturedPropertiesListPage from '../featured-properties-list/FeaturedPropertiesListPage'

class MainPage extends BasePage {
    constructor(props) {
        super(props)
        this.presenter = new MainPagePresenter(this, findObjectUseCase(), getCurrentUserUseCase(), signOutUseCase(), getAllSchemasUseCase())
    }

    componentDidMount() {
        this.presenter.componentDidMount()
    }

    onClickSignOut() {
        this.presenter.onClickSignOut()
    }

    onClickRegister() {
        this.navigateTo('/signup')
    }

    onClickSignIn() {
        this.navigateTo('/signin')
    }

    onClickFavourite() {
        this.navigateTo('/products/favorite')
    }

    onClickSeller() {
        this.redirect('https://seller.ebowr.com');
    }

    onClickMarketing() {
        this.navigateTo('/registration/marketing')
    }

    onClickMenu(e, item) {
        e.preventDefault()
        this.navigateTo(item.route)
    }

    onClickAccount() {
        const user = this.getCurrentUser()?.id

        this.navigateTo(`/profile`, {userId: user})
    }

    onClickHome() {
        this.navigateTo('/')
    }

    onClickVerified() {
        this.navigateTo('/get-verified')
    }

    onSubmitSearch(item) {
        this.navigateTo(`/search?product=${item}`)
    }

    render() {
        const user = this.getCurrentUser()
        const roles = this.getCurrentRoles()
        const searchInput = this.getQuery().product
        const roleCheck = roles?.some((item) => item.name === 'seller')
        const settings = [{
            name: 'Edit Account', route: '/account', icon: 'bi bi-person-check',
        },
        ]

        const menus = [{
            name: 'Products', icon: 'bi bi-bag', route: '/',
        },]


        return (<Layout>
            <div className='min-vh-100 d-flex flex-column'>
                <MainNavBar
                    onClickSignIn={this.onClickSignIn.bind(this)}
                    onClickSignOut={this.onClickSignOut.bind(this)}
                    onClickAccount={this.onClickAccount.bind(this)}
                    onClickFavourite={this.onClickFavourite.bind(this)}
                    onClickMenu={this.onClickMenu.bind(this)}
                    menus={menus}
                    onClickHome={this.onClickHome.bind(this)}
                    user={user}
                    roles={roles}
                    roleCheck={roleCheck}
                    searchInput={searchInput}
                    onClickRegister={this.onClickRegister.bind(this)}
                    onSubmitSearch={this.onSubmitSearch.bind(this)}
                    onClickSeller={this.onClickSeller.bind(this)}
                    onClickMarketing={this.onClickMarketing.bind(this)}
                    onClickVerified={this.onClickVerified.bind(this)}
                />

                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route
                        path='/products/:id'
                        element={<ProductDetailsPage/>}
                    />
                    <Route
                        path='/products/new-arrival'
                        element={<NewProductListPage/>}
                    />
                    <Route
                        path='/products/featured'
                        element={<FeaturedPropertiesListPage/>}
                    />
                    <Route path='/products/favorite' element={<FavoritePage/>}/>
                    <Route path='/products/checkout/:id' element={<CheckoutPage/>}/>
                    <Route
                        path='/registration/seller'
                        element={<SellerRegistrationPage/>}
                    />
                    <Route
                        path='/registration/marketing'
                        element={<MarketingRegistrationPage/>}
                    />
                    <Route
                        path='/registration/broker'
                        element={<BrokerRegistrationPage/>}
                    />
                    <Route
                        path='/registration/verify'
                        element={<VerifiedRegistrationPage/>}
                    />
                    <Route path='/success' element={<SuccessPage/>}/>
                    {/* <Route path={'/migration'} element={<MigrationPage />} /> */}
                    <Route path={'/profile'} element={<ProfilePage/>}/>
                    <Route path={'/get-verified'} element={<GetVerifiedPage/>}/>
                    <Route path='/search' element={<ResultListPage/>}/>
                    <Route element={<NotFoundPage/>}/>
                </Routes>
                <MainFooter/>
            </div>
        </Layout>)
    }
}

export default withRouter(MainPage)

import React from 'react'

function ProductListing({ children, count, title }) {
  return (
    <div className='container min-vh-100 pt-5'>
      <h1 className='fs-2 fw-semibold '>{title}</h1>
      <p className=''>
        {count} {count > 1 ? 'Properties' : 'Property'} Found
      </p>
      <div className='container overflow-auto' style={{ maxHeight: '42.5rem' }}>
        {children}
      </div>
    </div>
  )
}

export default ProductListing

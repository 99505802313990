import React from "react";
import formatCurrency from "../../formatCurrency";
import Queue from "nq";
import formatTimeAgo from "../../formatTimeAgo";
import { Bed, Size, Toilet } from "../../myIcons";

export default function ProductListItem({ product, onClick }) {
  return (
    <div className="col-xl-2 col-lg-4 col-6 p-1" role="button">
      <div className="card h-100 border-0 shadow-sm " onClick={onClick}>
        <img
          className="object-fit-cover card-img-top position-relative  "
          src={Queue.File.getFile(product?.thumbnail || "")}
          alt={product?.title}
          style={{ height: 190 }}
        />
        <div className="bg-secondary text-white position-absolute p-1 rounded-1 mx-2 mt-2 timer">
          {formatTimeAgo(product.createdAt)}
        </div>
        <div className="text-dark mb-0 p-2">
          <div className="">
            <h1 className="fs-6 fw-bold text-black text-capitalize clamp">
              {product?.title}
            </h1>
          </div>
          <div className="">
            <p className=" mb-0 fw-lighter text-dark me-auto col-10 text-truncate location">
              <i className="bi bi-geo-alt-fill"></i>{product?.location}
            </p>
          </div>
          <div className="text-end">
            {
              product.price && (
                    <p className="fs-6 fw-semibold mb-0 text-secondary">
                      {formatCurrency(product.price)}
                    </p>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import ProductListPresenter from './ProductListPresenter'
import {dialog} from 'nq-component'

import {countObjectUseCase, findObjectUseCase} from '../../usecases/object'

import {InfiniteScroll} from 'nq-component'
import withRouter from '../../withRouter'
import BaseListPage from '../../base/BaseListPage'
import ProductListSkeleton from '../../components/Skeleton/ProductListSkeleton'
import ProductListItem from '../../components/card/ProductListItem'

class ProductListPage extends BaseListPage {
    constructor(props) {
        super(props)
        this.presenter = new ProductListPresenter(this, findObjectUseCase(), countObjectUseCase())
    }

    getCollectionName() {
        return "properties";
    }


    onClickProduct(id) {
        this.navigateTo(`/products/details/${id}`)
    }

    onClickView() {
        this.navigateTo(`/products/new-arrival`)
    }

    closeDialog() {
        dialog.close()
    }

    getKeys() {
        return [
            'price',
            'createdAt',
            'thumbnail',
            'title',
            'location',
        ];
    }

    render() {
        const {objects, count, loading} = this.state

        return (
            <div className='container'>
                <InfiniteScroll
                    loadMore={this.loadMore.bind(this)}
                    hasMore={!loading && count > objects?.length}>
                    <div className='row'>
                        {objects?.map((item, i) => (
                            <ProductListItem
                                key={i}
                                product={item}
                                onClick={this.onClickItem.bind(this, i)}
                            />
                        ))}
                        {loading && <ProductListSkeleton count={6}/>}
                    </div>
                </InfiniteScroll>
            </div>
        )
    }
}

export default withRouter(ProductListPage)

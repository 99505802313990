import React from 'react'
import withRouter from '../../../withRouter'
import BannerImg from '../../../assets/banner.jpg'
import BannerImg1 from '../../../assets/banner1.png'
import BannerImg2 from '../../../assets/banner2.png'
import BannerImg3 from '../../../assets/banner3.png'
import BannerImg4 from '../../../assets/banner4.png'
import BannerImg5 from '../../../assets/banner5.png'
import BannerImg6 from '../../../assets/banner6.png'
import BannerImg7 from '../../../assets/banner7.png'
import { Link, useNavigate } from 'react-router-dom'
function Banner({ User }) {
  const navigate = useNavigate()



  return (

    <div className='container px-0 '>
      <div>
        <div className='d-md-flex gap-2'>
          <div className='w-100'>
            <div
              id='carouselExampleRide'
              className='carousel slide'
              data-bs-ride='true'
            >
              <div className='carousel-indicators'>
                <button
                  type='button'
                  data-bs-target='#carouselExampleRide'
                  data-bs-slide-to='0'
                  className='active '
                  aria-current='true'
                  aria-label='Slide 1'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleRide'
                  data-bs-slide-to='1'
                  aria-label='Slide 2'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleRide'
                  data-bs-slide-to='2'
                  aria-label='Slide 3'
                ></button>
              </div>
              <div className='carousel-inner'>
                <div className='carousel-item active'>
                  <img
                    src={BannerImg1}
                    className='object-fit-fill w-100 img-carousel'
                    alt='...'
                  />
                </div>
                <div className='carousel-item'>
                  <img
                    src={BannerImg2}
                    className='object-fit-fill w-100 img-carousel'
                    alt='...'
                  />
                </div>
                <div className='carousel-item'>
                  <img
                    src={BannerImg3}
                    className='object-fit-fill w-100 img-carousel'
                    alt='...'
                  />
                </div>
              </div>
              <button
                className='carousel-control-prev'
                type='button'
                data-bs-target='#carouselExampleRide'
                data-bs-slide='prev'
              >
                <span
                  className='carousel-control-prev-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Previous</span>
              </button>
              <button
                className='carousel-control-next'
                type='button'
                data-bs-target='#carouselExampleRide'
                data-bs-slide='next'
              >
                <span
                  className='carousel-control-next-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Next</span>
              </button>
            </div>
          </div>
          <div className='col-md-4 px-md-0 mt-md-0 mt-2 d-lg-block'>
            <div className='row g-2'>
              <div  className='col-md-12 col-6'>
                <img
                  src={BannerImg6}
                  className='object-fit-fill w-100 img-forms'
                  alt='...'
                />
              </div>
              <div
                className='col-md-12 col-6'
              >
                <img
                  src={BannerImg7}
                  className='object-fit-fill w-100 img-forms'
                  alt='...'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Banner

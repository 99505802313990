import Queue from "nq";

class ResetPasswordUseCase {

    constructor(repository) {
        this.repository = repository;

    }

    execute(email, options) {
        return new Queue.User().resetPassword(email, options)
    }

}

export default ResetPasswordUseCase;

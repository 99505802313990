import React from "react";

export default function MainFooter() {
  return (
    <div className="container-fluid bg-footer">
      <div className="row py-5 px-md-5 text-white">
        <div className="col-lg-4 d-lg-block d-flex justify-content-center  justify-content-between ">
          <p className="fw-bold fs-3 text-secondary">EBOWR</p>
          <div className="fs-3 d-flex gap-4">
            <i role="button" className="bi bi-facebook "></i>
            <i role="button" className="bi bi-twitter"></i>
            <i role="button" className="bi bi-instagram"></i>
            <i role="button" className="bi bi-tiktok"></i>
            <i role="button" className="bi bi-youtube"></i>
          </div>
        </div>
        <div className="col-4 d-none d-lg-block">
          <p className="fw-bold fs-5 mb-2">Explore</p>
          <p>About Us</p>
          <p>FAQs</p>
          <p>Privacy Policy</p>
          <p>Terms and Conditions</p>
          <p>Refund Policy</p>
        </div>
        <div className="col-4 d-none d-lg-block">
          <p className="fw-bold fs-5 s mb-2">Connect</p>
          <p>Contact Us</p>
          <p>Store List</p>
        </div>
      </div>
      <hr className="border border-light border-1 opacity-25"></hr>
      <div className="text-center text-white mb-4 ">© 2023 EBOWR</div>
    </div>
  );
}

import React from 'react'
import withRouter from '../../withRouter'
import BasePage from '../../base/BasePage'
import ProductListPage from '../product-list/ProductListPage'
import Banner from './component/Banner'

class HomePage extends BasePage {
    render() {
        const User = this.getCurrentUser()

        return (
            <div className='min-vh-100 py-4 container'>
                <div className='mb-3'>
                    <Banner User={User}/>
                </div>
                <div className='px-sm-0 px-lg-2 px-2 mt-4 pt-4  '>
                    <div className=''>
                        <h1 className='fs-2 fw-semibold '>Featured Properties</h1>
                        <p>One stop place where you can find your desire property</p>
                    </div>
                </div>
                <div className='px-sm-0 px-lg-2 px-2 mt-4 '>
                    <div className=''>
                        <h1 className='fs-4 fw-semibold '>Brand New</h1>
                        <hr className='border border-primary opacity-50 mt-0 '/>
                    </div>
                    <ProductListPage/>
                </div>
            </div>
        )
    }
}

export default withRouter(HomePage)

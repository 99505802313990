import BaseListPresenter from '../../base/BaseListPresenter'

class ResultListPresenter extends BaseListPresenter {
  constructor(view, findObjectUseCase) {
    super(view, findObjectUseCase)
  }

  componentDidUpdate(prevProps) {
    const query = this.view.getQuery()

    if (query.product && prevProps.query.product !== query.product) {
      this.getObjects()
    }
  }

  async getObjects() {
    this.view.scrollToTop()
    const collection = 'product'
    const product = this.view.getSearchInput()
    const fields = this.view.getSchema('product')?.fields

    const where = {status: 'Posted'}

    const or = [{ title: { $regex: product, $options: 'i' } }]
    

    Object.keys(fields || {})?.forEach((field) => {
      const options = fields[field]
      if (options.type === 'String') {
        const query = {}
        query[field] = { $regex: product, $options: 'i' }
        or.push(query)
      } else if (options.type === 'Boolean') {
        const query = {}
        query[field] = product === 'true'
        or.push(query)
      } else if (options.type === 'Pointer') {
        const query = {}
        query[field] = { $eq: product }
        or.push(query)
      }
    })
    
    where.$or = or

    const query = {
      
      where,
      include: ['all'],
    }
    this.view.showProgress()
    try {
      const objects = await this.findObjectUseCase.execute(collection, query)

      this.view.setObjects(objects)
      this.view.setCount(objects?.length || 0)

      this.view.hideProgress()
    } catch (error) {
      this.view.hideProgress()
      this.view.showError(error)
    }
  }
}

export default ResultListPresenter

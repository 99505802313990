import React from 'react'

function ProductDetailsSkeleton() {
  return (
    <div className='pb-4 pt-lg-4 pt-0  container min-vh-100'>
      <div className='row h-100'>
        <div className='col-lg-6 px-lg-2 px-0 d-flex flex-column align-self-stretch'>
          <div className='skeleton-loader mb-3 ' style={{ height: 400 }}></div>
        </div>
        <div className='col-lg-6 mt-lg-0 mt-2  border border-2 border-dark border-opacity-25 p-2 px-4 d-flex flex-column align-self-stretch'>
          <div className='row mb-2'>
            <div className='col'>
              <h1 className=''>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 40, width: 300 }}
                />
              </h1>
              <p className=''>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 120 }}
                />
              </p>
            </div>

            <div className='col-auto'>
              <div
                className='mb-2 skeleton-loader'
                style={{ height: 20, width: 40 }}
              />
            </div>
          </div>
          <hr className='border border-dark border opacity-20 mt-0'></hr>
          <div className=''>
            <div
              className='mb-2 skeleton-loader'
              style={{ height: 30, width: 50 }}
            />
            <div className='row px-3'>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
              <div className=' col-4 '>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 80 }}
                />
              </div>
            </div>
          </div>
          <hr className='border border-dark border opacity-20'></hr>
          <h2 className=' '>
            <div
              className='mb-2 skeleton-loader'
              style={{ height: 20, width: 60 }}
            />
          </h2>
          <div className=' mb-2 '>
            <div
              className='mb-2 skeleton-loader'
              style={{ height: 20, width: 80 }}
            />
          </div>
          <div
            className='mb-2 skeleton-loader'
            style={{ height: 40, width: '100%' }}
          />
        </div>
        <div className='container-fluid bg-white  mt-3 col-12  py-3 px-4'>
          <div className='row'>
            <div className='col-lg-1 col-md-2 col-3 d-flex justify-content-end align-items-center '>
              <div
                className='object-fit-cover rounded-circle skeleton-loader'
                style={{ height: 75, width: 75 }}
              />
            </div>
            <div className='col-lg-3 col-9 d-lg-block  d-flex flex-column    '>
              <div className=''>
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 200 }}
                />
                <div
                  className='mb-2 skeleton-loader'
                  style={{ height: 20, width: 180 }}
                />
              </div>

              <div
                className='mb-2 skeleton-loader'
                style={{ height: 30, width: 150 }}
              />
            </div>
            <div className='col-8  d-none d-lg-flex justify-content-between align-items-center '>
              <div className='row'>
                <div className='col-4 mb-2 px-md-4 d-flex justify-content-between'>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </div>
                <div className='col-4 mb-2 px-md-4 d-flex justify-content-between'>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </div>
                <div className='col-4 mb-2 px-md-4 d-flex justify-content-between'>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </div>
                <div className='col-4  px-md-4 d-flex justify-content-between'>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </div>
                <div className='col-4  px-md-4 d-flex justify-content-between'>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid bg-white  mt-3 col-12  p-4'>
          <h4 className='fw-semibold'>
            <div
              className='mb-2 skeleton-loader'
              style={{ height: 40, width: 200 }}
            />
          </h4>
          <hr className='border border-dark border opacity-20'></hr>
          <table className='table table-striped'>
            <tbody>
              <tr>
                <td className=''>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </td>
                <td className=''>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </td>
              </tr>
              <tr>
                <td className=''>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </td>
                <td className=''>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </td>
              </tr>
              <tr>
                <td className=''>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </td>
                <td className=''>
                  <div
                    className='mb-2 skeleton-loader'
                    style={{ height: 20, width: 80 }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='container-fluid bg-white  mt-3 col-12  p-4'>
          <h4 className='fw-semibold  '>
            <div
              className='mb-2 skeleton-loader'
              style={{ height: 40, width: 200 }}
            />
          </h4>
          <hr className='border border-dark border opacity-20'></hr>
          <div className='row'>
            <p className='col-md-8'>
              <div
                className='mb-2 skeleton-loader'
                style={{ height: 20, width: 200 }}
              />
              <div
                className='mb-2 skeleton-loader'
                style={{ height: 20, width: 300 }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductDetailsSkeleton

import BaseFormPresenter from '../../base/BaseFormPresenter'
import { findObjectUseCase } from '../../usecases/object'

class SellerRegistrationPresenter extends BaseFormPresenter {
  componentDidMount() {
    this.init()
    this.checkApplicationStatus()
    this.getObject()
  }

  componentDidUpdate(prevState) {
    const prevStatus = prevState.query.submit
    const newStatus = this.view.getQuery().submit
    if (prevStatus !== newStatus) {
      this.init()
      this.checkApplicationStatus()
      this.getObject()
    }
  }

  async checkApplicationStatus() {
    try {
      const collection = 'sellerApplications'
      const user = this.view.getCurrentUser()
      const query = {
        where: { user: { id: user?.id } },
        include: ['all'],
        sort: { createdAt: -1 },
      }
      this.view.showProgress()
      const [application] = await findObjectUseCase().execute(collection, query)
      this.view.hideProgress()
      console.log(application)
      if (application) {
        this.view.setApplicationStatus(application)
      }
    } catch (error) {
      this.view.setApplicationStatus(null)
      this.view.hideProgress()
      this.view.showError(error)
    }
  }

  async save() {
    const collection = 'sellerApplications'
    if (this.object.id) {
      this.change.id = this.object.id
    } else {
      this.change.acl = this.view.getAcl()
    }
    try {
      const user = this.view.getCurrentUser()
      this.change.user = { id: user.id }
      this.change.status = 'Pending'
      if(this.change.isBroker === true && this.change.prc_id === undefined){
        throw new Error('PRC ID is required')
      }
      await this.upsertUseCase.execute(collection, this.change)
    } catch (error) {
      throw error // rethrow the error to be caught by the caller
    }
  }
}

export default SellerRegistrationPresenter

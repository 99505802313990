import React from 'react'
import {Link} from 'react-router-dom'
import SignInPresenter from './SignInPresenter'
import {signInUseCase} from '../../usecases/user'
import {Button} from 'nq-component'
import BasePage from '../../base/BasePage'
import withRouter from '../../withRouter'
import InputFactory from '../../components/InputFactory'

class SignInPage extends BasePage {
    constructor() {
        super()
        this.presenter = new SignInPresenter(this, signInUseCase())
        this.state = {progress: false}
    }

    formSubmit(e) {
        e.preventDefault()
        this.presenter.submit()
    }

    getMasterKey() {
        return this.props.params && this.props.params.masterKey
    }

    onChange(value, field) {
        this.presenter.onChange(value, field)
    }

    render() {
        return (
            <div className='vh-100 bg-main'>
                <div className='d-flex h-100'>
                    <div className='col-md-4 m-auto container'>
                        <div className=' glass shadow rounded p-3 p-lg-4'>
                            <div className=' p-2 px-lg-4 py-lg-4'>
                                <h2 className='fw-bold mb-1'>Login</h2>
                                <Link
                                    to={'/'}
                                    role='button'
                                    className='w-100 d-flex justify-content-center py-3'>
                                    <img src="/logo.svg" style={{height: 140}}/>
                                </Link>
                                <form onSubmit={this.formSubmit.bind(this)}>
                                    <div className='row g-3'>
                                        <div className='col-md-12'>
                                            <label className='form-label fs-sm'>Email Address</label>
                                            <InputFactory
                                                required
                                                type='Email'
                                                autoComplete='nope'
                                                className='form-control'
                                                placeholder='Email Address'
                                                field='username'
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                        <div className='col-md-12'>
                                            <label className='form-label fs-sm'>Password</label>
                                            <InputFactory
                                                type='Password'
                                                required
                                                className='form-control'
                                                placeholder='Password'
                                                field='password'
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                        <div className='text-end'>
                                            <div className='mb-3 text-end'>
                                                <Link to='/forgot' className='fs-sm ms-2'>
                                                    Forgot your password?
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <Button
                                                progress={this.state.progress}
                                                type='submit'
                                                className='btn btn-primary w-50'
                                            >
                                                {this.state.progress ? 'Please wait...' : 'LOGIN'}
                                            </Button>
                                        </div>
                                        <div className='text-center'>
                      <span className='fs-sm'>
                        Don't have an account?
                        <Link to='/signup' className='ms-1'>
                          <span className='text-decoration-underline'>
                            Sign up
                          </span>
                        </Link>
                      </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SignInPage)

import React from "react";
import withRouter from "../../withRouter";
import formatCurrency from "../../formatCurrency";
import BasePage from "../../base/BasePage";
import CheckoutPresenter from "./CheckoutPresenter";
import { findObjectUseCase } from "../../usecases/object";
import { FormFactory, Spinner, dialog } from "nq-component";
import { Bed, Size, Toilet } from "../../myIcons";
import Queue from "nq";
import Gcash from "../../assets/gcash.png";
import Maya from "../../assets/maya.png";
import userSchema from "./userSchema.json";
import Seller from "../../assets/seller.jpg";
class CheckoutPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new CheckoutPresenter(this, findObjectUseCase());
    this.state = {
      product: {},
      progressCheckout: false,
    };
    this.radioInputGcash = React.createRef();
    this.radioInputMaya = React.createRef();
  }
  componentDidMount() {
    this.presenter.componentDidMount();
  }
  handleContainerClick = (ref) => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  getUserDefaultDetails(user) {
    const schema = {
      ...userSchema,
      fields: {
        ...userSchema.fields,
        first_name: {
          ...userSchema.fields.first_name,
          defaultValue: user?.first_name,
        },
        last_name: {
          ...userSchema.fields.last_name,
          defaultValue: user?.last_name,
        },
        email: {
          ...userSchema.fields.email,
          defaultValue: user?.email,
        },
        mobile: {
          ...userSchema.fields.mobile,
          defaultValue: user?.mobile,
        },
      },
    };

    return schema;
  }

  setProgressCheckout(progressCheckout) {
    this.setState({ progressCheckout });
  }

  closeDialog() {
    dialog.close();
  }
  getProductId() {
    const params = this.getParams();
    return params.id;
  }

  setProduct(product) {
    this.setState({ product });
  }

  setCheckout() {
    this.presenter.makeCheckout();
  }

  render() {
    const user = this.getCurrentUser();
    const schema = this.getUserDefaultDetails(user);

    const { product, progressCheckout } = this.state;
    console.log(user);
    if (progressCheckout)
      return (
        <div className="min-vh-100 d-flex justify-content-center align-items-center ">
          <Spinner Size="lg" />
        </div>
      );
    return (
      <div className="container p-3 min-vh-100">
        <div className="row">
          <div className="col-lg-6 order-lg-2 h-100 align-self-stretch">
            <div className="d-md-flex flex-column gap-3 bg-white rounded-3 border h-100 p-3 rounded-3 m-0 h-100">
              <img
                className="img-fluid rounded-3 "
                src={Queue.File.getFile(product?.thumbnail || "")}
                style={{ height: 300 }}
              />
              <div>
                <h1 className="text-capitalize fw-semibold">
                  {product?.title}
                </h1>
                <p className="fw-lighter text-dark">
                  <i className="bi bi-geo-alt"></i>{" "}
                  {product?.address
                    ? product.address
                    : `Brgy. ${product.barangay} ${product.city}, ${product.province}`}
                </p>
                <div className="d-flex gap-3 text-dark flex-wrap">
                  <p className="d-flex gap-1 align-items-center ">
                    <span className="text-primary">
                      <Bed />
                    </span>
                    {product.bedrooms} Bedrooms
                  </p>
                  <p className="d-flex gap-1 align-items-center">
                    <span className="text-primary">
                      <Toilet height="1.3rem" width="1.3rem" />
                    </span>
                    {product.bathrooms} Restrooms
                  </p>
                  <p className="d-flex gap-1 align-items-center">
                    <span className="text-primary">
                      <Size />
                    </span>
                    {product?.floorArea
                      ? product?.floorArea
                      : product?.landSize}
                    &#x33A1;
                  </p>
                </div>
                <h2 className="fw-semibold text-primary">
                  {formatCurrency(product?.pricePeso)}
                </h2>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="">
              <h1 className="fw-semibold mt-3 border text-center">Summary</h1>
              <div className='mt-4 ms-md-3 lh-base"'>
                <h4 className="text-capitalize fw-semibold">Buyer Details:</h4>
                <div className="row g-2">
                  <FormFactory
                    // hasLabel={false}
                    schema={schema}
                    // onChange={this.onChange.bind(this)}
                  />
                </div>
              </div>
              <div className="mt-4 ms-md-3">
                <h4 className="text-capitalize fw-semibold ">
                  Agency Details :
                </h4>
                <div className="bg-white d-flex align-items-center gap-3  p-4 rounded-3">
                  <div>
                    <img
                      src={Seller}
                      className="object-fit-cover rounded-circle bg-dark"
                      style={{ height: 75, width: 75 }}
                    />
                  </div>

                  <div className="">
                    <h5 className="mb-2">Bentong RealEstate Company</h5>
                    <p
                      className="fw-lighter text-white btn btn-primary mb-1"
                      role="button"
                      onClick={this.setCheckout.bind(this)}
                    >
                      <i className="bi bi-telephone-fill"></i> Show Number
                    </p>
                  </div>
                </div>

                {/* <div className='d-flex flex-column gap-2'>
                  <div
                    onClick={() =>
                      this.handleContainerClick(this.radioInputGcash)
                    }
                    className='p-3 rounded bg-white'
                  >
                    <input
                      className='form-check-input'
                      type='radio'
                      name='flexRadioDefault'
                      id='flexRadioDefault1'
                      ref={this.radioInputGcash}
                    />
                    <img
                      className='mx-2'
                      src={Gcash}
                      style={{ height: '1rem' }}
                    />
                  </div>
                  <div
                    onClick={() =>
                      this.handleContainerClick(this.radioInputMaya)
                    }
                    className='p-3 bg-white rounded'
                  >
                    <input
                      className='form-check-input'
                      type='radio'
                      name='flexRadioDefault'
                      id='flexRadioDefault2'
                      ref={this.radioInputMaya}
                    />
                    <img
                      className='mx-2'
                      src={Maya}
                      style={{ height: '1rem' }}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-end ">
          <button
            className="btn p-2 fs-6 fw-semibold btn-primary "
            onClick={this.setCheckout.bind(this)}
          >
            <i className="bi bi-telephone-fill"></i> Contact Now
          </button>
        </div>
      </div>
    );
  }
}
export default withRouter(CheckoutPage);

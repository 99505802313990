import browseFile from '../../browseFile'
import csvToJson from '../../csvToJson'
import unflatten from '../../unflatten'
import jsonToObject from '../../jsonToObject'
import BaseListPresenter from '../../base/BaseListPresenter'

class ProductListPresenter extends BaseListPresenter {
    onClickItem(index) {
        const object = this.objects[index];
        const collection = this.view.getCollectionName();
        this.view.navigateTo("/products/" + object.id);
    }
}

export default ProductListPresenter

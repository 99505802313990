import BaseListPage from '../../base/BaseListPage'
import FavouritePresenter from './FavouritePresenter'
import { deleteObjectUseCase, findObjectUseCase } from '../../usecases/object'
import FavouriteCard from './components/FavouriteCard'
import withRouter from '../../withRouter'
import { Spinner } from 'nq-component'

class FavoritePage extends BaseListPage {
  constructor(props) {
    super(props)
    this.presenter = new FavouritePresenter(
      this,
      findObjectUseCase(),
      deleteObjectUseCase()
    )
    this.state = {
      carts: [],
      progress: false,
      getProgress: false,
    }
  }
  getCarts() {
    return this.state.carts
  }

  setCarts(carts) {
    this.setState({ carts })
  }

  setGetProgress(getProgress) {
    this.setState({ getProgress })
  }

  onClickProduct(id) {
    this.navigateTo(`/products/details/${id}`)
  }

  onClickCheckout(id, id2) {
    this.presenter.checkoutNow(id)
    this.presenter.removeFavouritesCheck(id2)
  }
  onClickRemoveFavourites(id) {
    this.presenter.removeFavourites(id)
  }

  render() {
    const { carts, getProgress } = this.state
    if (getProgress)
      return (
        <div className='min-vh-100 d-flex justify-content-center align-items-center '>
          <Spinner Size='lg' />
        </div>
      )

    return (
      <div className='container pt-2 min-vh-100'>
        <h1 className='fw-semibold mt-3  '>Properties in your favourites</h1>
        <hr className='border border-secondary border-2 opacity-10'></hr>
        <div>
          <FavouriteCard
            carts={carts}
            onClickProduct={this.onClickProduct.bind(this)}
            onClickRemove={this.onClickRemoveFavourites.bind(this)}
            onClickCheckout={this.onClickCheckout.bind(this)}
          />
        </div>
      </div>
    )
  }
}
export default withRouter(FavoritePage)

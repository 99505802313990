import { LogoHolder, Menu } from "nq-component";
import React, { useEffect, useState } from "react";
import Search from "../../../components/Search";

import { useNavigate } from "react-router-dom";
export default function MainNavBar({
  onClickSignIn,
  onClickSignOut,
  onClickAccount,
  onClickFavourite,
  onClickHome,
  menus,
  user,
  cart,
  searchInput,
  roleCheck,
  onClickRegister,
  onSubmitSearch,
  onClickMarketing,
  onClickSeller,
  onClickVerified,
  roles,
}) {
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    if (searchInput === undefined) {
      setSearchVal("");
    } else {
      setSearchVal(searchInput);
    }
  }, [searchInput]);

  const onSubmitSearchProduct = (e) => {
    e.preventDefault();
    if (searchVal !== "") {
      onSubmitSearch(searchVal);
    }
  };
  return (
    <nav className="navbar sticky-top navbar-expand-lg bg-secondary shadow">
      <div className="container ">
        <a
          className="navbar-brand  py-2 d-lg-block d-none fw-semibold fs-4 text-white"
          role="button"
          onClick={() => onClickHome()}
        >
          EBOWR
        </a>

        <div className="container-fluid d-flex justify-content-between  align-items-center gap-3">
          <form
            onSubmit={onSubmitSearchProduct}
            className="input-group my-1 m-0 my-lg-0 position-relative"
            style={{
              maxWidth: "40rem",
            }}
          >
            <i
              className="bi bi-search  position-absolute"
              style={{
                zIndex: 10,
                width: "50px",
                height: "50px",
                lineHeight: "40px",
                textAlign: "center",
              }}
            />
            <input
              type="search"
              className="form-control rounded-pill ps-5 "
              placeholder="Search"
              onChange={(e) => setSearchVal(e.target.value)}
              value={searchVal}
            />
          </form>

          {user !== undefined ? (
            <div
              className="me-2 d-block text-end"
              role="button"
              onClick={() => onClickFavourite()}
            >
              <i className="bi bi-bag-heart text-white m-0 fs-4"></i>
              <span className="position-absolute top-25 start-25 translate-middle badge rounded-pill bg-danger fs-xs mt-1">
                {cart?.length}
              </span>
            </div>
          ) : null}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse justify-content-between align-items-center "
          id="navbarTogglerDemo02"
        >
          <div className="d-flex flex-lg-row flex-column align-items-center py-lg-0 py-3 gap-3">
            {user !== undefined ? (
              <>
                {roles?.some((item) => item.name === "seller") ||
                roles?.some((item) => item.name === "marketing") ? (
                  <div className="dropdown">
                    <div
                      className="no-select dropdown-toggle text-white d-flex align-items-center gap-2 py-0"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      role="button"
                    >
                      <LogoHolder
                        className="bg-primary "
                        textClassName="text-white fs-6"
                        logo={user?.avatar}
                        name={user?.username}
                        width={"38px"}
                        height={"38px"}
                      />
                      <p className="text-white my-0 ">
                        {user?.name || user?.username}
                      </p>
                    </div>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => onClickAccount()}
                          target="blank"
                        >
                          Profile
                        </a>
                      </li>
                      {!user?.isVerified && (
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => onClickVerified()}
                            role="button"
                          >
                            Get Verified
                          </a>
                        </li>
                      )}
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://seller.ebowr.com"
                          target="blank"
                        >
                          Seller
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="dropdown">
                    <div
                      className="no-select dropdown-toggle text-white d-flex align-items-center gap-2 py-0"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      role="button"
                    >
                      <LogoHolder
                        className="bg-primary"
                        textClassName="text-white fs-6"
                        logo={user?.avatar}
                        name={user?.username}
                        width={"38px"}
                        height={"38px"}
                      />
                      <p className="text-white my-0">
                        {user?.name || user?.username}
                      </p>
                    </div>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => onClickAccount()}
                          role="button"
                        >
                          Profile
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className='dropdown-item'
                          onClick={() => onClickVerified()}
                          role='button'
                        >
                          Get Verified
                        </a>
                      </li> */}
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => onClickSeller()}
                          role="button"
                        >
                          Apply as Seller
                        </a>
                      </li>

                    </ul>
                  </div>
                )}

                <button
                  onClick={() => onClickSignOut()}
                  className="btn btn-secondary text-white"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <a
                  onClick={() => onClickRegister()}
                  className="link-offset-2 link-underline link-underline-opacity-100 text-white"
                  role="button"
                >
                  Register
                </a>
                <button
                  onClick={() => onClickSignIn()}
                  className="btn btn-secondary text-white "
                >
                  Signin
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

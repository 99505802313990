import React, { Component } from 'react'
import withRouter from '../../withRouter'
import Verify from '../../assets/verify.svg'
import Step from '../../assets/step.svg'
import Secure from '../../assets/secure.svg'
import { set } from 'nq/src/Config'
import Seller from '../../assets/seller.jpg'
import BasePage from '../../base/BasePage'
class GetVerifiedPage extends BasePage {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onClickVerify() {
    this.navigateTo('/registration/verify')
  }
  render() {
    return (
      <div>
        <div className='bg-white'>
          <div className='container'>
            <div className='d-flex flex-lg-row  flex-column  justify-content-center align-items-center py-5'>
              <div className='col-lg-6 col-12 order-lg-1 order-2 '>
                <h2>Why Account Verification?</h2>
                <p>
                  Account verification is a crucial step towards ensuring the
                  safety and credibility of our platform. It helps build trust
                  among our users and creates a more transparent and secure
                  marketplace for all.
                </p>
              </div>
              <div className='col-lg-6 col-12 order-lg-2 order-1'>
                <img src={Verify} />
              </div>
            </div>
          </div>
        </div>
        <div className='py-5 section-bg'>
          <div className='container'>
            <div className='text-center'>
              <h4>Get Verified Today!</h4>
              <p>
                We encourage all our members to complete the verification
                process promptly. Verified accounts enjoy increased trust among
                our community members, and it opens up exciting opportunities
                for enhanced features and privileges on our platform.
              </p>
            </div>
          </div>
          <hr className='my-5' />
          <div className='container'>
            <div>
              <div className='d-flex flex-column flex-lg-row  justify-content-center gap-4 my-5'>
                <div className='col-lg-4 col-12'>
                  <div className='text-center'>
                    <h5>FULLY VERIFIED</h5>
                    <p>
                      Fully Verified RealEstate accounts have verified contact
                      details.
                    </p>
                  </div>
                  <div className='bg-white shadow-sm   mt-3 p-4 rounded-3'>
                    <div className='d-flex align-items-center gap-3'>
                      <div>
                        <img
                          src={Seller}
                          className='object-fit-cover rounded-circle bg-dark'
                          style={{ height: 75, width: 75 }}
                        />
                      </div>

                      <div className='text-start'>
                        <h5 className='mb-1'>Bentong Pimentel</h5>
                        <p className=' fw-bold text-success mb-0'>
                          <i className='bi bi-shield-fill-check'></i> FULLY
                          VERIFIED
                        </p>
                        <p className='text-dark fw-lighter mb-0'>
                          MEMBER SINCE 2021
                        </p>
                      </div>
                    </div>
                    <hr className='opacity-90'></hr>
                    <div className='text-center'>
                      <h6 className='fw-semibold'>Verified</h6>
                      <div className='d-flex gap-3 justify-content-center align-items-center '>
                        <i className='bi bi-telephone-fill text-primary fs-3'></i>
                        <i className='bi bi-whatsapp text-success fs-2'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <p className='m-0'>
                  To get a Fully Verified account, you will need to provide
                  additional crucial information.
                </p>
                <p>
                  We highly encourage you to add a profile photo and agency
                  photo to build trust with property seekers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white'>
          <div className='container pb-5'>
            <div className='d-flex flex-lg-row  flex-column gap-5 justify-content-center align-items-center pt-3'>
              <div className='col-lg-6 col-12  order-2 '>
                <h2>Benefits of having Verified account</h2>

                <ol>
                  <li>
                    Greater Exposure and Trust: When sellers' accounts are
                    verified, they gain more exposure and trust from potential
                    buyers. This happens because their information has been
                    confirmed, making them more trustworthy in the eyes of
                    prospective buyers.
                  </li>
                  <li>
                    Enhanced Credibility: Verification signifies higher
                    credibility for sellers. It indicates that they are genuine
                    professionals in the real estate field, which can help them
                    showcase their expertise in real estate matters.
                  </li>
                  <li>
                    Private Information: Through the verification process, we
                    can request detailed information about the sellers and their
                    businesses. This helps potential buyers get to know the
                    sellers better and learn about the services and products
                    they offer.
                  </li>

                  <li>
                    Promotions and Privileges: Verified sellers can also enjoy
                    privileges and promotions. For example, they may have
                    increased visibility on our platform or gain access to
                    special marketing tools.
                  </li>
                  <li>
                    Networking with Fellow Verified Sellers: We also provide
                    opportunities for verified sellers to connect with each
                    other. This can be a valuable resource for sharing
                    experiences and gaining insights from other professionals in
                    the real estate industry.
                  </li>
                </ol>
              </div>
              <div className='col-lg-6 col-12  order-1'>
                <img src={Step} />
              </div>
            </div>
          </div>
        </div>
        <div className='py-5 section-bg'>
          <div className='container'>
            <div className='d-flex flex-lg-row  flex-column  justify-content-center align-items-center py-5'>
              <div className='col-lg-6 col-12 order-lg-1 order-2 '>
                <h2>Privacy and Security Assured</h2>
                <p>
                  Rest assured that your private information will be treated
                  with the utmost care and in strict compliance with our privacy
                  policy. It will only be used for verification purposes and
                  communication within our platform.
                </p>
              </div>
              <div className='col-lg-6 col-12 order-lg-2 order-1'>
                <img src={Secure} />
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <button
                onClick={this.onClickVerify.bind(this)}
                className='btn btn-primary fw-semibold'
              >
                Verify Now
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(GetVerifiedPage)

import BaseListPresenter from '../../base/BaseListPresenter'

class NewProductListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    deleteObjectUseCase,
    upsertUseCase,
    exportCSVUseCase,
    addSchemaUseCase,
    updateSchemaUseCase,
    deleteSchemaUseCase
  ) {
    super(view, findObjectUseCase, deleteObjectUseCase)
    this.upsertUseCase = upsertUseCase
    this.exportCSVUseCase = exportCSVUseCase
    this.addSchemaUseCase = addSchemaUseCase
    this.updateSchemaUseCase = updateSchemaUseCase
    this.deleteSchemaUseCase = deleteSchemaUseCase
  }

  componentDidUpdate(prevProps) {
    const prevClassName = prevProps.params.name
    const newClassName = this.view.getCollectionName()
    //if collection change
    if (prevClassName !== newClassName) {
      this.init()
      this.getObjects()
    }
  }

  async getObjects() {
    const collection = 'product'
    window.scrollTo(0, 0)
    const skip = (this.current - 1) * this.limit
    const query = {
      skip: skip,
      where: { status: 'Posted' },
      include: ['all'],
      sort: this.sort,
    }
    this.view.showProgress()
    try {
      const objects = await this.findObjectUseCase.execute(collection, query)
      this.objects = this.objects.concat(objects)
      this.view.setCount(objects?.length || 0)
      this.view.setObjects(this.objects)
      this.view.hideProgress()
    } catch (error) {
      this.view.hideProgress()
      this.view.showError(error)
    }
  }
}

export default NewProductListPresenter

import BaseFormPresenter from '../../base/BaseFormPresenter'
import { getCurrentUserUseCase } from '../../usecases/user'

class AccountPresenter extends BaseFormPresenter {
  constructor(
    view,
    getObjectUseCase,
    upsertUseCase,

    signInUseCase
  ) {
    super(view, getObjectUseCase, upsertUseCase)
    this.signInUseCase = signInUseCase
    this.password = {}
  }

  onChangePassword(value, field) {
    this.password[field] = value
  }

  async getObject() {
    const collection = this.view.getCollectionName()
    const id = this.view.getObjectId()
    if (id) {
      const params = { include: ['all'] }
      try {
        this.view.showProgress()
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        })
        this.view.hideProgress()
        this.view.setObject(this.object)
      } catch (error) {
        this.view.hideProgress()
        this.view.showError(error)
      }
    }
  }

  async onChangePicture(value) {
    this.change['picture'] = value
    await this.submit()
    // this.view.reload()
    // window.location.reload()
  }

  async onSubmitPassword() {
    const { oldPassword, newPassword, confirmNewPassword } = this.password
    if (newPassword !== confirmNewPassword) {
      this.view.showError('Password must be the same')
      return
    }
    try {
      const user = this.object
      await this.signInUseCase.execute(
        {
          username: user.username,
          password: oldPassword,
        },
        { token: false }
      )
    } catch (error) {
      if (error.code === 404) {
        this.view.showError('Invalid old password')
        return
      }
      this.view.showError(error)
    }
  }
  async submit() {
    if (Object.values(this.change).length === 0) {
      this.view.navigateBack()
      return
    }
    try {
      // this.view.submitting();
      await this.save()

      // this.view.submissionSuccess();
      await this.view.showSuccess('Successfully saved!')
      window.location.reload()
    } catch (error) {
      this.view.submissionError(error)
      await this.view.showError(error)
      this.getObject()
    }
  }
}

export default AccountPresenter

import React, { Component } from 'react'
import withRouter from '../../withRouter'
import BaseListPage from '../../base/BaseListPage'
import NewProductListPresenter from './FeaturedPropertiesListPresenter'
import { findObjectUseCase } from '../../usecases/object'
import { InfiniteScroll, Progress, dialog } from 'nq-component'
import ProductListing from '../../components/product-listing/ProductListing'
import ProductListItem from '../../components/card/ProductListItem'

class FeaturedPropertiesListPage extends BaseListPage {
  constructor(props) {
    super(props)
    this.presenter = new NewProductListPresenter(this, findObjectUseCase())
    this.state = {
      objects: [],
      count: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.presenter.componentDidUpdate(prevProps, prevState)
  }

  onClickProduct(id) {
    this.navigateTo(`/products/details/${id}`)
  }

  closeDialog() {
    dialog.close()
  }
  render() {
    const schema = 'product'
    const title = 'Featured Properties'
    const { objects, count, progress } = this.state
    // console.log(objects)

    if (!schema) return <Progress />
    if (progress)
      return (
        <div className='min-vh-100 d-flex justify-content-center align-items-center '>
          <Progress />
        </div>
      )
    return (
      <ProductListing count={count} title={title}>
        <InfiniteScroll
          // className='h-100'
          loadMore={this.loadMore.bind(this)}
          hasMore={!progress && count > objects?.length}
        >
          <div className='row'>
            {objects?.map((item, i) => (
              <ProductListItem
                key={i}
                product={item}
                onClick={this.onClickProduct.bind(this, item?.id)}
              />
            ))}
          </div>
        </InfiniteScroll>
      </ProductListing>
    )
  }
}
export default withRouter(FeaturedPropertiesListPage)

import React from "react";
import AccountPresenter from "./AccountPresenter";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import BaseFormPage from "../../base/BaseFormPage";

import { InputInfo, InputInfoMerge } from "nq-component";
import fieldsPassword from "./fieldsPassword.json";
import { signInUseCase } from "../../usecases/user";
import { saveImageUseCase } from "../../usecases/file";
import click from "../../click";
import { InputImage } from "nq-component";
import withRouter from "../../withRouter";

class AccountPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {}, progress: true };
    this.presenter = new AccountPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      signInUseCase()
    );
    this.pictureRef = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setPerson(person) {
    this.setState({ person });
  }

  getCollectionName() {
    return "users";
  }
  getObjectId() {
    const id = this.getArgument()?.userId;

    return id;
  }

  // getObjectId() {
  //   return '2311013f-bde0-449c-ab33-8a90e5c1cd45'
  // }

  onClickProfile() {
    click(this.pictureRef.current);
  }

  onClickBroker() {
    this.navigateTo("/registration/broker");
  }

  onChangePassword(value, field) {
    this.presenter.onChangePassword(value, field);
  }

  onChangePicture(value) {
    this.presenter.onChangePicture(value);
  }

  onSubmitPassword(e) {
    e.preventDefault();
    this.presenter.onSubmitPassword();
  }

  navigateBack() {
    // override the default behavior
  }

  render() {
    const { object, progress } = this.state;
    const user = object;

    // console.log(this.getObjectId(), 'test')
    // const progress = false
    const schema = this.getSchema(this.getCollectionName());
    const roles = this.getCurrentRoles();

    // if (!user?.id) return 'test'
    if (!schema) return <div className="">no schema</div>;
    if (progress) return <div className="">Loading</div>;
    const name = user.name || user.username;
    const fields = schema.fields;

    return (
      <div className="min-vh-100 ">
        <div className="container bg-white py-4 mt-2  shadow">
          <div className="px-2 px-lg-5">
            <h1 className="fs-3 fw-semibold ">Personal Details</h1>
            <hr className="border border-primary opacity-50 mt-0 " />
          </div>

          <div className="row w-100 px-2 px-lg-5">
            <div className="col-12 col-lg-3">
              <div className="text-center position-relative ">
                <InputImage
                  value={user?.picture}
                  name={name}
                  onChange={this.onChangePicture.bind(this)}
                  saveImage={saveImageUseCase()}
                />

                <div className="position-absolute border-opacity-25 rounded-2 p-1 fs-sm text-muted border border-black text-black bg-white top-0 start-0 ">
                  <i className="bi bi-pencil-square"></i> Edit
                </div>
                <p className="m-0 fs-sm fw-bold mt-3 mb-1">{name}</p>
                <div className="d-flex gap-1 justify-content-center flex-wrap ">
                  {user?.isBroker ? (
                    <span className="m-0 badge rounded-pill bg-primary">
                      <i className="bi bi-shield-fill-check"></i> PROFESSIONAL
                      BROKER
                    </span>
                  ) : null}
                  {user?.isVerified ? (
                    <span className="m-0 badge rounded-pill bg-primary">
                      <i className="bi bi-shield-fill-check"></i> FULLY VERIFIED
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg">
              <span className="text-muted fs-sm">User Information</span>
              <ul className="list-group list-group-flush">
                {Object.keys(fields).map((field) => {
                  const { type, write, ...options } = fields[field];
                  if (write === false) return null;
                  if (field === "password") return null;
                  if (field === "picture") return null;
                  if (type !== "String") return null;
                  const icon = options.icon || "bi bi-card-checklist";

                  return (
                    <InputInfo
                      key={field}
                      icon={icon}
                      field={field}
                      object={user}
                      onChange={this.onChange.bind(this)}
                      onSubmit={this.onSubmitForm.bind(this)}
                      label={options.label || field + ":"}
                      placeholder="fist-name last-name"
                    />
                  );
                })}
                <InputInfoMerge
                  fields={fieldsPassword}
                  object={user}
                  onChange={this.onChangePassword.bind(this)}
                  onSubmit={this.onSubmitPassword.bind(this)}
                  label="Password:"
                />
                <InputInfo
                  type="Date"
                  icon="bi bi-link-45deg"
                  field="createdAt"
                  object={user}
                  label="Joined:"
                  edit={false}
                />
              </ul>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountPage);

import React from 'react'

function ProductListSkeleton({count}) {
    const skeletonItems = new Array(count).fill(null)
    return (
        <>
            {skeletonItems.map((_, i) => (
                <div key={i} className='rounded col-xl-2 col-lg-4 col-6 p-1'>
                    <div className='card p-2 gap-2 '>
                        <div className='skeleton-loader' style={{height: 191}}></div>
                        <div className='skeleton-loader' style={{height: 21}}></div>
                        <div className='skeleton-loader' style={{height: 21}}></div>
                        <div className='skeleton-loader' style={{height: 21}}></div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ProductListSkeleton
